<template>
  <certificate-preview
     :certificate="certificate"
     role="производитель"
  />
</template>

<script>
// @ is an alias to /src
import { CertificatePreview } from "@/components";
import { mapActions, mapState } from 'vuex';

export default {
  name: "ManufacturerCertificatePreview",
  components: {
    CertificatePreview
  },
  data() {
    return {
    }
  },
  methods: {
    ...mapActions('certificates', [
      "fetchManufacturerCertificate",
      "fetchManufacturerIdCertificateId",
      "fetchManufacturerCertificateByKey"
    ])
    
  },
  computed: {
    ...mapState({partnerId: state => state.auth.user.partnerId}),
    ...mapState('certificates', {
      certificate: state => { return {
        certificateName: state.certificate.manufacturerName,
        legalAdress: state.certificate.manufacturerName,
        address: state.certificate.address,
        certificateImage: state.certificate.certificateImage,        
        isRevoked: state.certificate.isRevoked,
      }}
    })
  },
  created() {
    if (this.$route.params.certificateId)
      this.fetchManufacturerIdCertificateId({id: this.$route.params.id, certificateId: this.$route.params.certificateId})    
    else if (this.$route.query.Key)
      this.fetchManufacturerCertificateByKey(this.$route.query.Key)    
    else
      this.fetchManufacturerCertificate()
  },
};
</script>

<style lang="scss" scoped></style>